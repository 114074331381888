<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";
@import "@/scss/styles.scss";
</style>

<script>

export default {
  name: "App",
};
</script>
