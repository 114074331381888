<template>
  <BaseModal
    :modalHeader="$t('how_to_use')"
    :modalId="this.modalId"
    :useBtnClose="this.isAgree"
    :noCloseBackdrop="!this.isAgree"
  >
    <div slot="modal-body">
      <div class="htu-file">
        <img :src="this.getImage" @load="checkIfIsLoadedImage" />
      </div>

      <div class="htu-actions" v-if="this.isLoadedImage">
        <div class="agree-actions" v-if="!isAgree">
          <a @click="showDiffModal('modal-tac')" class="item-action">
            {{ $t("terms_and_condition") }}
          </a>

          <div class="message">
            <b-form-checkbox id="checkedAgree" v-model="checkBoxAgreeFlg">
              {{ $t("agree_message") }}
            </b-form-checkbox>
          </div>

          <ButtonPrimary
            :content="this.btnSubmit.content"
            :disabled="this.btnSubmit.disabled"
            @click="this.onClick"
          />

          <a @click="showDiffModal('modal-faq')" class="item-action">
            {{ $t("faq") }}
          </a>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script>
import { ButtonPrimary } from "@components"
import BaseModal from "../BaseModal"
import { Box } from "@utils"
import { bulkIssueUrlService } from "@services"

export default {
  name: "HowToUseModal",
  components: {
    BaseModal,
    ButtonPrimary,
  },
  props: {
    howToUseFile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalId: "modal-htu",
      agreeFlg: false,
      isAgree: false,
      isLoadedImage: false,
      btnSubmit: {
        content: this.$t("ok"),
        disabled: true,
      },
    };
  },
  computed: {
    getImage: function () {
      return this.howToUseFile.full_path_image || null;
    },
    checkBoxAgreeFlg: {
      set: function (value) {
        this.agreeFlg = !!value; //parse to boolean
        this.btnSubmit.disabled = !this.agreeFlg;
      },
      get: function () {
        return this.agreeFlg;
      },
    },
  },
  methods: {
    checkIfIsLoadedImage: function () {
      this.isLoadedImage = true;
    },
    showDiffModal: function (modalId) {
      this.$bvModal.hide(this.modalId);
      this.$bvModal.show(modalId);
    },
    onClick: async function () {
      this.btnSubmit.disabled = true;

      const { data } = await bulkIssueUrlService.agreeFlg();
      Box.setBox(data);

      this.isAgree = !!Box.getAgreeFlg(); // parse Boolean
      this.$bvModal.hide(this.modalId);
    },
  },
  mounted() {
    this.isAgree = !!Box.getAgreeFlg(); // parse Boolean

    if (!this.isAgree) {
      this.$bvModal.show("modal-htu");
    }
  },
};
</script>
