import BaseService from "../BaseService";
import { HEADER_REQUEST_EGIFT_CATALOG_UUID, API_PAGINATE_ALL } from "@constants";
import {
    HTTP_CODE_UNPROCESSABLE_ENTITY,
} from "@constants";

const BASE_URI = `/egift-catalogs/products`;

/**
 * Egift catalog product
 */
class ProductService extends BaseService {
    // Find catalog products data by catalog_bulk_issue_urls.gift_catalog_url
    async index(egiftCatalogUUID, headerRequest = {}) {
        const res = await this.get(BASE_URI, {}, {
            limit: API_PAGINATE_ALL,
            [HEADER_REQUEST_EGIFT_CATALOG_UUID]: egiftCatalogUUID,
            ...headerRequest,
        })
        .then((response) => {
            return response;
        })

        return res.data;
    }

    /**
     * Exchange selected product for egift catalog
     *
     * @param int egiftCatalogUUID
     * @param { product_id } params
     * @returns
     */
    async exchangeProduct(egiftCatalogUUID, formData) {
        const res = await this.post(
            BASE_URI + '/exchange',
            formData,
            {
                [HEADER_REQUEST_EGIFT_CATALOG_UUID]: egiftCatalogUUID,
            }
        ).then((response) => {
            if (response.data.status == HTTP_CODE_UNPROCESSABLE_ENTITY) {
                let responseData = {
                    status: HTTP_CODE_UNPROCESSABLE_ENTITY,
                };

                responseData.code = response.data.data.code;
                response.data = responseData;
            }

            return response;
        });

        return res;
    }
}

export default new ProductService();
