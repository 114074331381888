import axios from "axios";
import { HTTP_CODE_NOT_FOUND, HTTP_CODE_INTERNAL_SERVER_ERROR } from "@constants";
import router from "@router";

axios.defaults.baseURL = process.env.VUE_APP_API_DOMAIN;

export default class BaseService {
    async get(uri, params = {}, header = {}) {
        try {
            return await axios.get(uri, {
                params: params,
                headers: {
                    ...header,
                },
            });
        } catch (e) {
            return this._errorMsg(e);
        }
    }

    async post(uri, params = {}, header = {}) {
        try {
            return await axios.post(
                uri,
                params,
                {
                    headers: {
                        ...header,
                    },
                }
            );
        } catch (e) {
            return this._errorMsg(e);
        }
    }

    async put(uri, params = {}, header = {}) {
        try {
            return await axios.put(uri, {
                params: params,
                headers: {
                    ...header,
                },
            });
        } catch (e) {
            return this._errorMsg(e);
        }
    }

    async patch(uri, params = {}, header = {}) {
        try {
            return await axios.patch(uri, {
                params: params,
                headers: {
                    ...header,
                },
            });
        } catch (e) {
            return this._errorMsg(e);
        }
    }

    async show(uri, header = {}) {
        try {
            return await axios.get(uri, {
                headers: {
                    ...header,
                },
            });
        } catch (e) {
            return this._errorMsg(e);
        }
    }

    async delete(uri, header = {}) {
        try {
            return await axios.delete(uri, {
                headers: {
                    ...header,
                },
            });
        } catch (e) {
            return this._errorMsg(e);
        }
    }

    _errorMsg(e) {
        if (e.response === undefined) {
            e.status = 0;
            e.statusText = e.message;
            return { data: e };
        }

        // Special status process
        switch (e.response.status) {
            case HTTP_CODE_NOT_FOUND: // Redirect to 404 page
                // Diff domain if was giftee_boxes
                var apiUrl = e.response.config.url;
                if(/check-gift-box/.test(apiUrl)) {
                    var uuid = apiUrl.split('/check-gift-box/').pop();
                    window.location.href = `/giftee_boxes/${uuid}/error-404`;
                }

                router.push({ path: '/error-404' });
                break;
            case HTTP_CODE_INTERNAL_SERVER_ERROR: // Redirect to 500 page
                router.push({ path: '/error-500' });
            break;
        }

        // Response format if was another status error
        return { data: e.response };
    }
}
