<template>
  <div class="count-number">
    <button
      :class="{
        'products-icon': true,
        disabled: !decreasable,
      }"
      :disabled="!decreasable"
      @click="decrease"
    >
      <span class="icon minus-icon img-responsive" size="32"></span>
    </button>
    <div class="products-number">
      <input
        ref="input"
        type="number"
        autocomplete="off"
        placeholder=""
        :value="value"
        :min="min"
        :max="max"
        :step="step"
        :disabled="(!decreasable && !increasable)"
        @keypress="keypress"
        @change="change"
        @paste="paste"
      />
    </div>
    <button
      :class="{
        'products-icon': true,
        disabled: !increasable,
      }"
      :disabled="!increasable"
      @click="increase"
    >
      <span class="icon plus-icon img-responsive" size="32"></span>
    </button>
  </div>
</template>

<style scoped lang="scss">
@import "./styles.scss";
</style>

<script>
const isNaN = Number.isNaN || window.isNaN;
const REGEXP_NUMBER = /^(?:\d+)$/;

export default {
  name: "QuantityCounter",
  props: {
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: Infinity,
    },
    step: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: NaN,
    },
    disableQuantity: {
        type: Boolean,
        default: false,
    }
  },
  emits: ["update:value"],
  data() {
    return {
      value: NaN,
    };
  },
  computed: {
    /**
     * Indicate if the value is increasable.
     * @returns {boolean} Return `true` if it is decreasable, else `false`.
     */
    increasable: function () {
      if (this.disableQuantity) {
        return false;
      }
      return isNaN(this.value) || this.value < this.max;
    },

    /**
     * Indicate if the value is decreasable.
     * @returns {boolean} Return `true` if it is decreasable, else `false`.
     */
    decreasable: function () {
      if (this.disableQuantity) {
        return false;
      }

      return isNaN(this.value) || this.value > this.min;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          !(isNaN(newValue) && typeof oldValue === "undefined") &&
          newValue !== this.value
        ) {
          this.setValue(newValue);
        }
      },
    },
  },
  methods: {
    /**
     * Keypress allow positive numbers only
     * @param {Event} event - Event object.
     */
    keypress: function (event) {
      if (event.charCode >= 48) {
        return true;
      }
      event.preventDefault();
    },
    /**
     * Change event handler.
     * @param {Event} event - Event object.
     */
    change: function (event) {
      this.setValue(event.target.value);
    },
    /**
     * Paste event handler.
     * @param {Event} event - Event object.
     */
    paste: function (event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      if (clipboardData && !REGEXP_NUMBER.test(clipboardData.getData("text"))) {
        event.preventDefault();
      }
    },
    /**
     * Decrease the value.
     */
    decrease() {
      if (this.decreasable) {
        let { value } = this;
        if (isNaN(value)) {
          value = 0;
        }
        this.setValue(value - this.step);
      }
    },
    /**
     * Increase the value.
     */
    increase() {
      if (this.increasable) {
        let { value } = this;
        if (isNaN(value)) {
          value = 0;
        }
        this.setValue(value + this.step);
      }
    },
    /**
     * Set new value and dispatch change event.
     * @param {number} value - The new value to set.
     */
    setValue: function (value) {
      const oldValue = this.value;
      let newValue = parseInt(value);
      if (!isNaN(newValue)) {
        if (this.min <= this.max) {
          newValue = Math.min(this.max, Math.max(this.min, newValue));
        }
      } else {
        newValue = this.min;
      }
      this.value = newValue;
      if (newValue === oldValue) {
        // Force to override the number in the input box.
        this.$refs.input.value = newValue;
      }
      this.$emit("update:value", newValue);
    },
  },
};
</script>
