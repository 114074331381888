import BaseService from "./BaseService";

class AuthService extends BaseService {
    async checkGiftBox (uuid) {
        const respone = await this.get(`/check-gift-box/${uuid}`);
        return respone.data;
    }
}

export default new AuthService();
