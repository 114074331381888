<template>
  <div class="loading">
    <div class="loading-content" :style="this.loadingStyle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        style="enable-background: new 0 0 50 50"
        xml:space="preserve"
      >
        <path
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./styles.scss";
</style>

<script>
import { IMAGES } from "@assets";
import { DEFAULT_LOADING_FILL, DEFAULT_LOADING_WIDTH, DEFAULT_LOADING_HEIGHT } from "@constants";

export default {
  name: "ScreenLoading",
  data() {
    return {
      IMAGES,
    };
  },
  computed: {
    loadingStyle: function () {
      return {
        fill: DEFAULT_LOADING_FILL,
        width: DEFAULT_LOADING_WIDTH,
        height: DEFAULT_LOADING_HEIGHT,
      };
    },
  },
};
</script>
