import BaseService from "./BaseService";

const BASE_URI = `/categories`;

class CategoriesService extends BaseService {
    async index () {
        const res = await this.get(BASE_URI)

        return res.data;
    }
}

export default new CategoriesService();
