import { render, staticRenderFns } from "./ConfirmLoading.vue?vue&type=template&id=d1262040&scoped=true&"
var script = {}
import style0 from "./ConfirmLoading.scss?vue&type=style&index=0&id=d1262040&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1262040",
  null
  
)

export default component.exports