import VueLoadImage from "vue-load-image";
import { IMAGES } from "@assets";

export default {
    name: "ThumbnailLoader",
    components: {
        "vue-load-image": VueLoadImage,
    },
    computed: {
        dataSrc: function () {
            return this.src || this.loading_error;
        }
    },
    data() {
        return {
            IMAGES,
        }
    },
    props: {
        src: {
            type: String|null,
            required: true,
        },
        loading_width: {
            type: Number,
            required: false,
            default: 104,
        },
        loading_height: {
            type: Number,
            required: false,
            default: 104,
        },
        loading_error: {
            default: IMAGES.image_loading,
        }
    }
}
