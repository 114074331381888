<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    hide-footer
    :ok-disabled="true"
    :hideHeaderClose="false"
    modal-class="modal-basic"
    :no-close-on-backdrop="noCloseBackdrop"
    @hide="onHideModal"
  >
    <!-- Modal Header -->
    <div slot="modal-header" class="header-container">
      <a class="btn-close" @click="onClickCloseModal" v-if="this.useBtnClose">
        <span class="icon close-icon" size="16"></span>
      </a>
      <h3 class="header-title">{{ this.modalHeader }}</h3>
    </div>

    <slot name="modal-body"></slot>
  </b-modal>
</template>

<style lang="scss" scoped>
@import "./styles.scss";
</style>

<script>
import { Box } from "@utils";

export default {
  name: "BaseModal",
  props: {
    modalId: {
      type: String,
      required: true,
    },
    modalHeader: {
      type: String,
      required: true,
    },
    useBtnClose: {
      type: Boolean,
      default: true,
    },
    noCloseBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onHideModal: function () {
      if (!Box.getAgreeFlg()) {
        this.$bvModal.show("modal-htu");
      }
    },
    onClickCloseModal: function () {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

