import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// Routes
import { routes } from "./routes";

// Services
import { authService } from "@services";
import { Box } from "@utils";

// Store
import store from '@store';

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title ?? "Giftee";

    // Check for requireGiftBox here
    if (to.matched.some((record) => record.meta.requireGiftBox)) {
        // If first was enter (first time load)
        let uuid = to.params.uuid;
        if (Box.getGiftBoxUuid() != uuid) {
            Box.clearBox();

            await authService.checkGiftBox(uuid).then((response) => {
                const data = response.data;

                // Set gift box data
                Box.setBox(data);
            });
        }

        let blockRouteNames = [
            'products.show',
            'products.my-gifts'
        ];

        // If is box was not agree go to homepage and show agree popup
        if (!Box.getAgreeFlg() && blockRouteNames.includes(to.name)) {
            next({
                name: "products.index",
                params: {
                    uuid: to.params.uuid,
                },
            });
        } else {
            next();
        }
    } else if(to.matched.some((record) => record.meta.requireEgiftCatalog)) {
        let uuid = to.params.uuid;

        // load initial data for egift catalog
        await store.dispatch("egiftCatalog/loadInitial", uuid);

        // check redirect (if already have egift_url) or next
        let egiftUrl = store.getters['egiftCatalog/currentEgiftUrl'];
        if(egiftUrl) {
            window.location.href = egiftUrl;
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
