import Vue from 'vue'
import VueI18n from 'vue-i18n'
import vnMessage from './vn'
import enMessage from './en'
import idMessage from './id'
import { APP_LANG_DEFAULT, APP_LANG_VN, APP_LANG_ID } from "@constants"

Vue.use(VueI18n)

export const LANGUAGES = {
   [APP_LANG_DEFAULT]: 'en',
   [APP_LANG_VN]: 'vn',
   [APP_LANG_ID]: 'id',
}

const messages = {
  vn: vnMessage,
  en: enMessage,
  id: idMessage,
}

const i18n = new VueI18n({
  messages,
  fallbackLocale: LANGUAGES[APP_LANG_DEFAULT],
  silentTranslationWarn: true,
})

export default i18n
