<template>
  <div class="products-empty-wrapper">
    <div class="pe-thumbnail">
      <img :src="IMAGES.empty_list" alt="" />
    </div>
    <div class="pe-info">
      <h2 class="pe-title"> {{ this.$t('messages.no_data') }} </h2>
    </div>
  </div>
</template>

<script>
import { IMAGES } from "@assets";

export default {
  name: "ProductsListEmpty",
  data() {
    return {
      IMAGES,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
