<template>
  <div class="filter-area">
    <div v-if="title" class="filter-title">
      <h4>{{ this.title }}</h4>
    </div>
    <div class="filter-body">
      <slot name="inside-filter-body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterArea",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@import "./styles.scss";
</style>
