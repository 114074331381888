import { IMAGES } from "@assets";
import { Box } from "@utils";

// components
import VueLoadImage from "vue-load-image";
import BaseHeader from "../BaseHeader/BaseHeader.vue";

export default {
  name: "HeaderLanguages",
  components: {
    "vue-load-image": VueLoadImage,
    BaseHeader
  },
  data() {
    return {
      IMAGES,
      selectedLangId: 0,
      logoFileName: null,
    };
  },
  props: {
    languages: {
        type: Array,
        default: () => { return [] },
    },
  },
  computed: {
    getGiftBoxLogo: function () {
        return Box.getLogoFile();
    }
  },
  methods: {
    onChangeLanguage: function () {
      Box.setLanguage(this.selectedLangId);
      this.$router.go();
    },
    // Load keyword data to search
    loadKeywordFilter: function () {
      // Call loadKeywordFilter from SearchModal Component
      this.$root.$refs.SearchModal.loadKeywordFilter();
    }
  },
  created() {
    this.selectedLangId = Box.getLanguage();
  },
};
