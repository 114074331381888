import { render, staticRenderFns } from "./GiftCardItem.html?vue&type=template&id=904daf06&scoped=true&"
import script from "./GiftCardItem.js?vue&type=script&lang=js&"
export * from "./GiftCardItem.js?vue&type=script&lang=js&"
import style0 from "./GiftCardItem.scss?vue&type=style&index=0&id=904daf06&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "904daf06",
  null
  
)

export default component.exports