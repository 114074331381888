import Vue from "vue";
import App from "./App.vue";

//====== BootstrapVue
import {
    ModalPlugin,
    ListGroupPlugin,
    FormCheckboxPlugin,
    PaginationPlugin
} from "bootstrap-vue";

Vue.use(ModalPlugin);
Vue.use(ListGroupPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(PaginationPlugin);

//====== FontAwesome
import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDown,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
library.add([faAngleDown, faTimesCircle]);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

//====== Route
import router from "./router";

//====== Locale
import i18n from "@i18n";

//====== Toastr
import {
    VueToastr
} from "@components";
Vue.use(VueToastr);

//====== Store (Vuex)
import store from '@store';

//====== Vue Gtag
import { gtag } from "@plugins";

new Vue({
    gtag,
    router,
    i18n,
    store,
    render: function (h) {
        return h(App);
    },
}).$mount("#app");
