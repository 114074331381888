import BaseService from "../BaseService";
import { HEADER_REQUEST_EGIFT_CATALOG_UUID } from "@constants";

const BASE_URI = `/egift-catalogs/composite`;

/**
 * Egift catalog composite API
 */
class CompositeService extends BaseService {
    // Find composite data by catalog_bulk_issue_urls.gift_catalog_url
    async composite(uuid) {
        const res = await this.get(BASE_URI, {}, {
            [HEADER_REQUEST_EGIFT_CATALOG_UUID]: uuid,
        })
        .then((response) => {
            return response;
        })

        return res.data;
    }
}

export default new CompositeService();
