<template>
  <BaseModal :modalHeader="$t('terms_and_condition')"  modalId="modal-tac">
    <div slot="modal-body" v-html="getContent"></div>
  </BaseModal>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script>
import BaseModal from "../BaseModal"

export default {
  name: "TermsAndConditionModal",
  components: {
    BaseModal,
  },
  props: {
    termsOfUse: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getContent: function () {
      return this.termsOfUse.content || null;
    },
  },
};
</script>

