<template>
  <footer id="footer">
    <div class="footer-nav-container">
      <router-link
        v-for="(navBarItem, i) in navBarItems"
        :key="i"
        :to="prepareFilterQuery(navBarItem.displayName)"
        :exact="navBarItem.exact"
        active-class="active"
        class="footer-nav-single"
      >
        <div class="menu-container">
          <div class="menu-icon">
            <span v-if="navBarItem.displayName == $t('gifts')" @click="handleGiftBtn" size="24" class="icon" :class="navBarItem.icon"></span>
            <span v-else size="24" class="icon" :class="navBarItem.icon"></span>
          </div>
          <span class="menu-text"> {{ $t(navBarItem.displayName) }} </span>
        </div>
      </router-link>
    </div>
  </footer>
</template>

<style scoped lang="scss">
@import "./styles.scss";
</style>

<script>
import { SEARCH_FILTER } from "@constants";
import { Box } from "@utils";

export default {
  name: "Footer",
  data() {
    return {
      navBarItems: [
        {
          displayName: this.$t("gifts"),
          name: "products.index",
          icon: 'gift-icon',
          exact: true,
        },
        {
          displayName: this.$t("my_gifts"),
          name: "products.my-gifts",
          icon: 'my-gift-icon',
          exact: true,
        },
      ],
      searchFilterQuery: {}
    };
  },
  methods: {
    checkRouteActive: function (routePath) {
      return this.$route.matched.some(({ name }) => name === routePath);
    },
    getSearchFilter: function () {
      // Get search filter query from session storage
      let filterData = JSON.parse(sessionStorage.getItem(SEARCH_FILTER + Box.getGiftBoxUuid()));
      let routeQuery= this.$route.query;
      let routeQueryData= this.objectNotEmpty(this.$route.query) ? routeQuery : {};
      // Set filter query
      filterData || routeQueryData ? this.searchFilterQuery = filterData || routeQueryData : this.searchFilterQuery = {};
    },
    prepareFilterQuery: function (typeBtn) {
      // Check when click button "Gifts"
      if (typeBtn == this.$t('gifts')) {
        // Have query will push it to route path
        if (this.objectNotEmpty(this.searchFilterQuery)) {
          return {
            name: this.navBarItems[0].name,
            query: this.searchFilterQuery
          }
        } else {
          // Remove key if search filter is empty
          sessionStorage.removeItem(SEARCH_FILTER + Box.getGiftBoxUuid());
          return {
            name: this.navBarItems[0].name
          }
        }
      } else {
        return {
          name: this.navBarItems[1].name
        } 
      }
    },
    objectNotEmpty: function(obj) {
      if (Object.keys(obj).length != 0) {
        return true;
      } else {
        return false;
      }
    },
    handleGiftBtn: function() {
      this.getSearchFilter();
    }
  },
  watch: {
    "$route.query": {
      handler: function() {
        this.getSearchFilter();
        this.prepareFilterQuery(this.$t('gifts'));
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getSearchFilter();
  }
};
</script>
