import BaseService from "./BaseService";

const BASE_URI = `/composite`;

class CompositeApiService extends BaseService {
    async index (params = {}) {
        const res = await this.get(BASE_URI + "/gifts-page", params)

        return res.data;
    }

    async footerModal (params = {}) {
        const res = await this.get(BASE_URI + "/footer-modal", params)

        return res.data;
    }
}

export default new CompositeApiService();
