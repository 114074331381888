import ThumbnailLoader from "@components/ThumbnailLoader";
import {
    GIFT_CARD_TYPE_EGIFT,
    GIFT_CARD_TYPE_IKEDAYAMA,
    GIFT_CARD_TYPE_EGIFT_CATALOG,
    GIFT_CARD_TYPE_GIFT_BOX,
} from "@constants";
import { formatDate } from "@utils";
import { IMAGES } from "@assets";

export default {
    name: "GiftCardItem",
    data() {
        return {
            IMAGES,
        };
    },
    components: {
        ThumbnailLoader,
    },
    props: {
        giftCardItem: {
            type: Object,
            required: true,
        },
        giftCardType: {
            type: Number,
            required: true,
        },
    },
    computed: {
        itemUrl: function () {
            let itemUrl = null;

            switch (this.giftCardType) {
                case GIFT_CARD_TYPE_EGIFT:
                    itemUrl = this.giftCardItem.egift_url;
                    break;
                case GIFT_CARD_TYPE_IKEDAYAMA:
                    itemUrl = this.giftCardItem.gift_card_url;
                    break;
                case GIFT_CARD_TYPE_EGIFT_CATALOG:
                    itemUrl = this.giftCardItem.gift_catalog_url;
                    break;
                default:
                    itemUrl = this.giftCardItem.gift_box_url;
            }

            return itemUrl;
        },
        itemImage: function () {
            let itemImage = null;

            switch (this.giftCardType) {
                case GIFT_CARD_TYPE_EGIFT:
                case GIFT_CARD_TYPE_IKEDAYAMA:
                    itemImage = this.giftCardItem.product.full_path_image;
                    break;
                case GIFT_CARD_TYPE_EGIFT_CATALOG:
                    itemImage = this.giftCardItem.catalog.full_path_image;
                    break;
                default:
                    itemImage = this.giftCardItem.gift_box.full_path_image;
            }

            return itemImage;
        },
        itemName: function () {
            let itemName = null;

            switch (this.giftCardType) {
                case GIFT_CARD_TYPE_EGIFT:
                case GIFT_CARD_TYPE_IKEDAYAMA:
                    itemName = this.giftCardItem.product.name;
                    break;
                case GIFT_CARD_TYPE_EGIFT_CATALOG:
                    itemName = this.giftCardItem.catalog.name;
                    break;
                default:
                    itemName = this.giftCardItem.gift_box.name;
            }

            return itemName;
        },
        itemBrandName: function () {
            if (this.giftCardType == GIFT_CARD_TYPE_EGIFT) {
                return this.giftCardItem.product.brand.name;
            } else {
                return null;
            }
        },
        itemExpiryDate: function () {
            return formatDate(this.giftCardItem.expiry_date);
        },
        expiryDateLabel: function () {
            let label = null;

            if(this.giftCardType == GIFT_CARD_TYPE_GIFT_BOX) {
                label = this.$t("point_expiry_period");
            } else {
                label = this.$t("expiry_period");
            }

            return label;
        },
        thumbnailError: function () {
            if (this.giftCardType == GIFT_CARD_TYPE_EGIFT) {
                return IMAGES.image_loading;
            } else {
                if (this.giftCardType == GIFT_CARD_TYPE_EGIFT_CATALOG) {
                    return IMAGES.image_catalog_thumbnail_error;
                }

                return IMAGES.image_thumbnail_error;
            }
        },
    },
    methods: {
        isIkedayamaProductType: function () {
            return this.giftCardType == GIFT_CARD_TYPE_IKEDAYAMA;
        },
    },
};
