import {
    HEADER_TYPE_DEFAULT,
    HEADER_TYPE_DISABLE,
    FOOTER_TYPE_DEFAULT,
    FOOTER_TYPE_DISABLE,
} from "@constants"

// Master
const GiftCardMaster = () => import ('@views/GiftCard')

// Egift Exchange Code
const EgiftExchangeCode = () => import('@views/EgiftExchangeCode')

// Errors
const Error500 = () => import('@views/Errors/containers/Error500')
const Error404 = () => import('@views/Errors/containers/Error404')

// Pattern for meta field
let setMetaField = function (
    title, {
        headerType = HEADER_TYPE_DEFAULT,
        footerType = FOOTER_TYPE_DEFAULT,
        headerBackBtn = false,
        headerTitle = null
    } = {}
) {
    return {
        title: title,
        headerType: headerType,
        footerType: footerType,
        headerConfig: {
            title: headerTitle,
            useBackBtn: headerBackBtn,
        },
    };
};

export const routes = [
    {
        path: "/giftee_cards/:uuid",
        component: GiftCardMaster,
        meta: setMetaField("Gift Card"),
    },
    {
        path: "/error-500",
        name: "error-500",
        component: Error500,
        meta: setMetaField("Server Error", {
            footerType: FOOTER_TYPE_DISABLE,
        }),
    },
    {
        path: "*",
        name: "error-404",
        component: Error404,
        meta: setMetaField("Page not found", {
            footerType: FOOTER_TYPE_DISABLE,
        }),
    },
    {
        path: "/exchange_egift/:slug_name",
        component: EgiftExchangeCode,
        meta: {
            title: "Egift Exchange Code",
            headerType: HEADER_TYPE_DISABLE,
            footerType: FOOTER_TYPE_DISABLE,
        },
    },
];
