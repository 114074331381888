import { SELECT_ALL } from "@constants";
import { IMAGES } from "@assets";
import { FilterArea, BaseModal } from "../components";

export default {
  name: "CategoriesModal",
  data() {
    return {
      IMAGES,
      selectedCategoryId: null,
    };
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    defaultCategoryId: {
      type: Number,
      default: SELECT_ALL,
    },
  },
  components: {
    FilterArea,
    BaseModal,
  },
  methods: {
    onClickCategory: function (event) {
      this.selectedCategoryId = event.target.getAttribute("data-id");
    },
    submitCategory: function () {
      this.$emit("submit", this.selectedCategoryId);
    }
  },
  computed: {
    getCategories: function () {
      return this.categories.map((category) => {
        return {
          id: category.id,
          name: category.name,
        };
      });
    },
    categoriesHeight: function () {
      let height = (window.innerHeight * 65) / 100;
      return height + "px";
    },
  },
  watch: {
    defaultCategoryId: {
        handler: function (value) {
            this.selectedCategoryId = value;
        },
    },
  },
  mounted() {
    this.selectedCategoryId = this.defaultCategoryId;
  }
};
