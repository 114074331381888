const BOX_KEY = 'gift_box';

const getBox = () => {
    if (!sessionStorage.getItem(BOX_KEY)) {
        return null;
    }

    return JSON.parse(sessionStorage.getItem(BOX_KEY));
};

const setBox = (data) => {
    data = JSON.stringify({
        id: data.id,
        gift_box_url: data.gift_box_url,
        last_accessed_at: data.last_accessed_at,
        exchangeable_point: data.exchangeable_point,
        default_language_id: data.default_language_id,
        agree_terms_conditons_flg: data.agree_terms_conditons_flg,
        full_path_logo_image: data.full_path_logo_image,
        wallet_charge_issue: data?.wallet_charge_issue,
        template_wallet_allow_charge_flg: data?.template_wallet_allow_charge_flg,
    });

    sessionStorage.setItem(BOX_KEY, data);
};

const getGiftBoxUuid = () => {
    let box = getBox();

    return box?.gift_box_url || null;
}

const getLanguage = () => {
    let giftBoxLang = sessionStorage.getItem('gift_box_lang_' + getGiftBoxUuid());

    return parseInt(giftBoxLang) || 0;
}

const setLanguage = (languageId) => {
    sessionStorage.setItem('gift_box_lang_' + getGiftBoxUuid(), languageId);
}

const getCurrentPoint = () => {
    let box = getBox();

    return box.current_point;
}

const getExchangeablePoint = () => {
    let box = getBox();

    return box.exchangeable_point || 0;
}

const getAgreeFlg = () => {
    let box = getBox();

    return box?.agree_terms_conditons_flg || false;
}

const clearBox = () => {
    sessionStorage.removeItem(BOX_KEY);
}

const getLogoFile = () => {
    let box = getBox();

    return box?.full_path_logo_image || null;
}

export default {
    getBox,
    setBox,
    getGiftBoxUuid,
    getLanguage,
    setLanguage,
    getCurrentPoint,
    getExchangeablePoint,
    getAgreeFlg,
    clearBox,
    getLogoFile,
};
