var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-box"},[_c('div',{staticClass:"product-card"},[_c('router-link',{attrs:{"to":{
        name: 'products.show',
        params: {
          id: _vm.product.id,
        },
      }}},[_c('div',{staticClass:"product-card-thumbnail"},[_c('vue-load-image',[_c('div',{staticClass:"product-thumbnail",style:({
              'background-image': ("url(" + (_vm.product.image_link) + ")"),
            }),attrs:{"slot":"image","data-src":_vm.product.image_link},slot:"image"}),_c('img',{staticClass:"loading img-responsive",attrs:{"slot":"preloader","width":"117","height":"117","src":_vm.IMAGES.image_loading,"alt":"loading"},slot:"preloader"}),_c('img',{staticClass:"error img-responsive",attrs:{"slot":"error","width":"117","height":"117","src":_vm.IMAGES.image_loading,"alt":"error"},slot:"error"})])],1),_c('div',{staticClass:"product-card-info"},[_c('div',{staticClass:"brand-name"},[_c('span',[_vm._v(" "+_vm._s(_vm.product.brand.name)+" ")])]),_c('div',{staticClass:"name"},[_c('span',[_vm._v(" "+_vm._s(_vm.product.name)+" ")])]),_c('PointCount',{attrs:{"point":_vm.product.exchanged_point}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }