import BaseService from "./BaseService";
import router from "@router";
import { Box } from "@utils";

import {
    HTTP_CODE_UNPROCESSABLE_ENTITY,
    HTTP_CODE_NOT_FOUND,
    CODE_REDEEM_FAILURE,
    CODE_REDEEM_NOT_ENOUGH_POINT,
    CODE_REDEEM_PRODUCT_ACTIVE_FLG_INVALID,
    CODE_CREATED_OK,
} from "@constants";

const BASE_URI = `/products/template-products`;
const BASE_URI_REDEEM = `/products/redeem`;

class ProductService extends BaseService {
    async index(params = {}) {
        const res = await this.get(BASE_URI, params);
        return res.data;
    }

    async show(id, params = {}) {
        const res = await this.get(BASE_URI + `/${id}`, params)
        .then((response) => {
            if(response.data.status == HTTP_CODE_NOT_FOUND) {
                window.location.href = window.location.href + `/error-404`;
            }

            return response;
        })
        return res.data;
    }

    async redeemPoint(params = {}) {
        const res = await this.post(BASE_URI_REDEEM, params)
            .then((response) => {
                if (response.data.status == HTTP_CODE_UNPROCESSABLE_ENTITY) {
                    let responseData = {
                        status: 422,
                        code: null,
                        message: "Unprocessable Entity"
                    };

                    switch (response.data.data.code) {
                        case CODE_REDEEM_PRODUCT_ACTIVE_FLG_INVALID:
                            responseData.code = CODE_REDEEM_PRODUCT_ACTIVE_FLG_INVALID;
                            responseData.message = "messages.redeem_product_active_flg_invalid";
                            break;
                        case CODE_REDEEM_NOT_ENOUGH_POINT:
                            responseData.code = CODE_REDEEM_NOT_ENOUGH_POINT;
                            responseData.message = "messages.redeem_fail";
                            break;
                        case CODE_REDEEM_FAILURE:
                            responseData.code = CODE_REDEEM_FAILURE;
                            responseData.message = "messages.redeem_fail";
                            break;
                    }

                    response.data = responseData;
                } else if (response.data.status != CODE_CREATED_OK) {
                    router.push({ name: 'box-error-500', params: { uuid: Box.getGiftBoxUuid() } })
                }

                return response;
            });

        return res;
    }
}

export default new ProductService();
