import { formatDate } from "@utils";
import { IMAGES } from "@assets";
import VueLoadImage from "vue-load-image";

export default {
    name: "EgiftCatalogProduct",
    components: {
        "vue-load-image": VueLoadImage,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        isExpiredEgiftCatalog: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
          IMAGES,
        };
    },
    computed: {
        brandName: function () {
            return this.product.brand.name;
        },
        expiryDate: function() {
            return this.$t('expired_date')  + ' ' + formatDate(this.product.expire_date);
        },
        disableProduct: function () {
            // if was disable catalog -> disable product
            if(this.isExpiredEgiftCatalog) {
                return true;
            }

            // If product was limit reached
            if (this.product.limit_reached && this.product.exchanged_count >= this.product.limit_reached) {
                return true;
            }

            // return disable status
            return this.product.is_expired;
        },
        productLimitReached: function() {
            if (this.product.limit_reached && this.product.exchanged_count >= this.product.limit_reached) {
                return true;
            }
            return false;
        }
    },
    methods: {
        onClickSelectProduct: function() {
            // Enable click event when egift catalog was not expired && product have status ON
            if(!this.disableProduct) {
                this.$emit('show-product', this.product);
            }
        }
    }
}
