const state = {
    isFetching: false,
}

const getters = {
    getFetching: state => {
        return state.isFetching;
    }
}

const mutations = {
    setFetching: (state, isFetching) => {
        state.isFetching = isFetching;
    }
}

const actions = {
    setFetching({ commit }, isFetching) {
        commit('setFetching', isFetching);
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
