import { BaseModal } from "../components";
import { SEARCH_FILTER } from "@constants";
import { Box } from "@utils";

export default {
    name: "SearchModal",
    components: {
        BaseModal,
    },
    data: function () {
        return {
            searchText: '',
        }
    },
    methods: {
        // event on click refresh btn
        onClickRefreshBtn: function () {
            this.searchText = '';
        },
        // Load keyword data to search
        loadKeywordFilter: function () {
            let keywordQuery = this.$route.query.keyword || '';

            this.searchText = keywordQuery;

            // Set product filter query to session storage
            sessionStorage.setItem(SEARCH_FILTER + Box.getGiftBoxUuid(), JSON.stringify(this.$route.query));
        }
    },
    computed: {
        // refresh btn style binding
        refreshBtnClass: function () {
            return {
                'btn-refresh': true,
                'invisible': !this.searchText,
            }
        }
    },
    created() {
        // Call load data search by keyword
        this.loadKeywordFilter();

        // Init refs for this Component
        this.$root.$refs.SearchModal = this;
    }
}
