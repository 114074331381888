import BaseService from "./BaseService";

class GiftCardService extends BaseService {
    async findByUUID (uuid) {
        const res = await this.get(`/gift-cards/${uuid}`)
        .then((response) => {
            return response.data;
        });

        return res;
    }
}

export default new GiftCardService();
