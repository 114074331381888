import BaseService from "./BaseService";

const BASE_URI = `/my-gifts`;

class MyGiftService extends BaseService {
    /**
     * Get Data
     * @param object params
     * @returns
     */
    async getData(params = {}) {
        const res = await this.get(BASE_URI, params)
        return res.data;
    }


    /**
     * Change mark redeem for a exchange product
     *
     * @param exchangeProductId (template_exchange_products.id)
     * @param redeemedFlg
     */
    async markRedeemed(exchangeProductId, redeemedFlg) {
        const res = await this.post(BASE_URI + '/mark-redeemed', {
            exchange_product_id: exchangeProductId,
            redeemed_flg: Number(redeemedFlg),
        })
        .then(response => {
            return response;
        });

        return res.data;
    }
}

export default new MyGiftService();
