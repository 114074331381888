import { IMAGES } from "@assets";
import { Box } from "@utils";

// components
import VueLoadImage from "vue-load-image";
import BaseHeader from "../BaseHeader/BaseHeader.vue";

export default {
    name: "Header",
    components: {
        "vue-load-image": VueLoadImage,
        BaseHeader
    },
    data() {
        return {
            IMAGES,
        };
    },
    props: {
        useBackBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
        },
    },
    computed: {
        getGiftBoxLogo: function () {
            return Box.getLogoFile();
        }
    },
};
