import VueLoadImage from "vue-load-image";
import { IMAGES } from "@assets";
import { formatDate } from "@utils";
import { egiftCatalogProductService } from "@services";
import {
    HTTP_CODE_UNPROCESSABLE_ENTITY,
    CODE_EGIFT_CATALOG_EXCHANGE_EXPIRED,
    CODE_EGIFT_CATALOG_EXCHANGE_PRODUCT_ACTIVE_FLG_INVALID,
    CODE_EGIFT_CATALOG_EXCHANGE_PRODUCT_LIMIT_REACHED,
    CODE_CREATED_OK,
    ACTIVE_ON_FLG
} from "@constants";

export default {
    name: "EgifCatalogSelectProductModal",
    components: {
        "vue-load-image": VueLoadImage,
    },
    data() {
        return {
          IMAGES,
          disableConfirmBtn: false,
          errorMsg: null,
        };
    },
    props: {
        product: {
            required: false,
            default: null,
        },
        selectedModalId: {
            type: String,
            required: true,
        }
    },
    watch: {
        product: {
          handler(newValue) {
            if(newValue.inactive_flg != ACTIVE_ON_FLG) {
                this.errorMsg = this.$t('messages.egift_catalog_exchange_product_active_flg_invalid');
                this.disableConfirmBtn = true;
            } else {
                this._clearMsg();
                this.disableConfirmBtn = false;
            }
          },
        },
    },
    computed: {
        expiryDate: function () {
            if(!this.product) {
                return null;
            }

            return this.$t('expired_date') + ' ' + formatDate(this.product.expire_date);
        },
    },
    methods: {
        // Event when hidden modal
        onHiddenModal: function() {
            // clear message
            if(this.product.inactive_flg == ACTIVE_ON_FLG) {
                this._clearMsg();
                this.disableConfirmBtn = false;
            }
        },
        // Event when click 'Cancel'
        onCloseModal: function () {
            this.$bvModal.hide(this.selectedModalId);
        },
        // Event confirm product when click 'Select this product'
        onConfirmSelectedProduct: async function () {
            let egiftCatalogUUID = this.$route.params.uuid;

            // prepare before exchange
            this.disableConfirmBtn = true;
            this._clearMsg();

            // Call api exchange product
            const { data } = await egiftCatalogProductService.exchangeProduct(
                egiftCatalogUUID,
                {
                    product_id: this.product.id,
                }
            );

            if (data.status == CODE_CREATED_OK) { // exchange successfully
                window.location.href = data.egift_url;
            } else if (data.status == HTTP_CODE_UNPROCESSABLE_ENTITY) { // exchange failure
                switch(data.code) {
                    case CODE_EGIFT_CATALOG_EXCHANGE_PRODUCT_ACTIVE_FLG_INVALID:
                        this.errorMsg = this.$t('messages.egift_catalog_exchange_product_active_flg_invalid');
                        this.disableConfirmBtn = false;
                        break;
                    case CODE_EGIFT_CATALOG_EXCHANGE_EXPIRED:
                        this.errorMsg = this.$t('messages.egift_catalog_exchange_expired');
                        this.disableConfirmBtn = false;
                        break;
                    case CODE_EGIFT_CATALOG_EXCHANGE_PRODUCT_LIMIT_REACHED:
                        this.errorMsg = this.$t('messages.egift_catalog_exchange_product_limit_reached');
                        this.$emit('update-product-limit-reached', this.product);
                        this.disableConfirmBtn = true;
                        break;
                    default:
                        this.errorMsg = this.$t('messages.egift_catalog_exchange_fail');
                        this.disableConfirmBtn = false;
                }
            }
        },
        // clear messsage
        _clearMsg: function () {
            this.errorMsg = null;
        }
    }
}
