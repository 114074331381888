<template>
  <div class="loading">
    <div class="loading-content" :style="loadingStyle">
      <svg
        version="1.1"
        id="loading-dots"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        xml:space="preserve"
      >
        <path
          :style="circleStyle"
          d="M60.952,195.048C27.343,195.048,0,222.391,0,256s27.343,60.952,60.952,60.952
      s60.952-27.343,60.952-60.952S94.562,195.048,60.952,195.048z"
        >
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            begin="0.1"
            repeatCount="indefinite"
          />
        </path>
        <path
          :style="circleStyle"
          d="M256,195.048c-33.609,0-60.952,27.343-60.952,60.952s27.343,60.952,60.952,60.952
      s60.952-27.343,60.952-60.952S289.609,195.048,256,195.048z"
        >
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            begin="0.2"
            repeatCount="indefinite"
          />
        </path>
        <path
          :style="circleStyle"
          d="M451.048,195.048c-33.609,0-60.952,27.343-60.952,60.952s27.343,60.952,60.952,60.952
      S512,289.609,512,256S484.657,195.048,451.048,195.048z"
        >
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            begin="0.3"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./styles.scss";
</style>

<script>
import {
  DEFAULT_LOADING_FILL,
  DEFAULT_LOADING_WIDTH,
  DEFAULT_LOADING_HEIGHT,
} from "@constants";

export default {
  name: "ListLoading",
  props: {
    fill: {
      type: String,
      default: DEFAULT_LOADING_FILL,
    },
    size: {
      type: Object,
      default: () => {
        return {
          width: DEFAULT_LOADING_WIDTH,
          height: DEFAULT_LOADING_HEIGHT,
        };
      },
    },
  },
  computed: {
    loadingStyle: function () {
      return {
        width: this.size.width,
        height: this.size.height,
      };
    },
    circleStyle: function () {
      return {
        fill: this.fill,
      };
    },
  },
};
</script>
