<template>
  <div class="product-boxs">
    <div
      class="product-boxs-list"
      v-if="!isFetching && getProducts.length >= 1"
    >
      <ProductBoxItem
        v-for="product in this.getProducts"
        :key="product.id"
        :product="product"
      />
    </div>

    <div
      class="product-boxs-container"
      v-if="!isFetching && getProducts.length < 1"
    >
    <ProductsListEmpty />
    </div>
  </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script>
import ProductBoxItem from "../ProductBoxItem";
import ProductsListEmpty from "../ProductsListEmpty";

export default {
  name: "ProductBoxList",
  components: {
    ProductsListEmpty,
    ProductBoxItem,
  },
  props: {
    isFetching: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getProducts: function () {
      return this.products.map((product) => {
        return {
          id: product.id,
          name: product.name,
          brand: product.brand,
          image_link: product.image_link,
          popular_flg: product.popular_flg,
          exchanged_point: product.template_exchanged_point,
        };
      });
    },
  },
};
</script>


