<template>
  <div class="product-box">
    <div class="product-card">
      <router-link
        :to="{
          name: 'products.show',
          params: {
            id: product.id,
          },
        }"
      >
        <div class="product-card-thumbnail">
          <vue-load-image>
            <div
              slot="image"
              class="product-thumbnail"
              :style="{
                'background-image': `url(${product.image_link})`,
              }"
              :data-src="product.image_link"
            />
            <img
              slot="preloader"
              class="loading img-responsive"
              width="117"
              height="117"
              :src="IMAGES.image_loading"
              alt="loading"
            />
            <img
              slot="error"
              class="error img-responsive"
              width="117"
              height="117"
              :src="IMAGES.image_loading"
              alt="error"
            />
          </vue-load-image>
        </div>
        <div class="product-card-info">
          <div class="brand-name">
            <span> {{ product.brand.name }} </span>
          </div>
          <div class="name">
            <span> {{ product.name }} </span>
          </div>
          <PointCount :point="product.exchanged_point" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./styles.scss";
</style>

<script>
import { IMAGES } from "@assets";
import VueLoadImage from "vue-load-image";
import PointCount from "@components/PointCount";

export default {
  name: "ProductBoxItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      IMAGES,
    };
  },
  components: {
    PointCount,
    "vue-load-image": VueLoadImage,
  },
};
</script>



