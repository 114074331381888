<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    hide-footer
    :ok-disabled="true"
    modal-class="modal-filter-basic"
  >
    <!-- Modal Header -->
    <div slot="modal-header" class="header-container">
      <a class="btn-close" @click="hideModal">
        <span class="icon close-icon" size="16"></span>
      </a>
      <h3 class="header-title"> {{ this.modalHeader }} </h3>
    </div>

    <!-- Modal Body -->
    <keep-alive>
        <slot name="modal-body"></slot>
    </keep-alive>

    <!-- Modal Confirm -->
    <div class="modal-actions">
      <div class="row">
        <div class="col-12">
          <ButtonPrimary @click="onSubmit" :content="$t('apply')" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script>
import { IMAGES } from "@assets";
import ButtonPrimary from "@components/ButtonPrimary";

export default {
  name: "BaseModal",
  data() {
    return {
      IMAGES,
    };
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    modalHeader: {
      type: String,
      required: true,
    },
  },
  components: {
    ButtonPrimary,
  },
  methods: {
    hideModal: function () {
      this.$bvModal.hide(this.modalId);
    },
    onSubmit: function () {
        this.$emit('submit');
        this.$refs[this.modalId].hide();
    }
  },
};
</script>
