import BaseService from "./BaseService";
import {
    HTTP_CODE_EGIFT_EXCHANGE_CODE_NOT_EXIST,
    HTTP_CODE_EGIFT_EXCEED_LIMIT_EXCHANGE_CODE,
    HTTP_CODE_UNPROCESSABLE_ENTITY,
    HTTP_CODE_BAD_REQUEST,
    HTTP_CODE_OK,
    MINIMUM_TIMEOUT_LOADING
} from "@constants";

class EgiftExchangeCodeService extends BaseService {
    // Get settings
    async getSettings (slugName) {
        const res = await this.get(`/client-exchange-page/${slugName}/settings`)
        .then((response) => {
            return response.data;
        });

        return res;
    }

    // Exchanging code
    async exchangingCode (slugName, code) {
        const res = await this.get(`/client-exchange-page/exchange-code/${slugName}`, { exchange_code: code })
        .then((response) => {
            const statusCode = response.data.status;
            const res = response.data;

            if(
                statusCode == HTTP_CODE_EGIFT_EXCHANGE_CODE_NOT_EXIST ||
                statusCode == HTTP_CODE_BAD_REQUEST ||
                statusCode == HTTP_CODE_UNPROCESSABLE_ENTITY ||
                statusCode == HTTP_CODE_EGIFT_EXCEED_LIMIT_EXCHANGE_CODE
            ) {
                return response.data;
            }

            if(statusCode == HTTP_CODE_OK && res.data.egift_url) {
                setTimeout(() => (
                    // Redirect to Egift Url
                    window.location.href = res.data.egift_url
                ), MINIMUM_TIMEOUT_LOADING);
            }
        });

        return res;
    }
}

export default new EgiftExchangeCodeService();
