import Paginator from "./handlePaginator"
import Box from "./handleBox"

import moment from "moment"
import { API_FORMAT_DATE } from "@constants"
import i18n from "@i18n"

// Format point number
export const formatPointNumber = (point) => {
    return point.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

// Format date
export const formatDate = (date) => {
    let format = i18n.tc('format_date');
    let locale = i18n.tc('locale');

    return moment(date, API_FORMAT_DATE).locale(locale).format(format);
};

export {
    Paginator,
    Box,
};
