import BaseService from "./BaseService";

const BASE_URI = `/bulk-issue-urls`;

class BulkIssueUrlService extends BaseService {
    async agreeFlg () {
        const res = await this.get(BASE_URI + "/agree-flg");

        return res.data;
    }
}

export default new BulkIssueUrlService();
