import BaseService from "./BaseService";

const BASE_URI = `/languages`;

class LanguageService extends BaseService {
    async index (params = {}) {
        const res = await this.get(BASE_URI, params)
        return res.data
    }
}

export default new LanguageService();
