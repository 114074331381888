// Header type
export const HEADER_TYPE_DISABLE = -1;
export const HEADER_TYPE_DEFAULT = 1;
export const HEADER_TYPE_LANGUAGES = 2;

// Footer type
export const FOOTER_TYPE_DISABLE = -1;
export const FOOTER_TYPE_DEFAULT = 1;

// HTTP code
export const HTTP_CODE_BAD_REQUEST = 400
export const HTTP_CODE_UNAUTHORIZED = 401
export const HTTP_CODE_FORBIDDEN = 403
export const HTTP_CODE_NOT_FOUND = 404
export const HTTP_CODE_METHOD_NOT_ALLOW = 405
export const HTTP_CODE_UNPROCESSABLE_ENTITY = 422
export const HTTP_CODE_INTERNAL_SERVER_ERROR = 500
export const HTTP_CODE_OK = 200

// Egift exchange code
export const HTTP_CODE_EGIFT_EXCHANGE_CODE_NOT_EXIST = 423
export const HTTP_CODE_EGIFT_EXCEED_LIMIT_EXCHANGE_CODE = 425

// Api status code
export const CODE_CREATED_OK = 201

// === Gift box
export const CODE_REDEEM_FAILURE = 453
export const CODE_REDEEM_NOT_ENOUGH_POINT = 454
export const CODE_REDEEM_BOX_EXPIRED = 455
export const CODE_REDEEM_PRODUCT_EXPIRED = 456
export const CODE_REDEEM_PRODUCT_ACTIVE_FLG_INVALID = 457

// ==== Egift Catalog
export const CODE_EGIFT_CATALOG_EXCHANGE_FAILURE = 453
export const CODE_EGIFT_CATALOG_EXCHANGE_EXPIRED = 455
export const CODE_EGIFT_CATALOG_EXCHANGE_PRODUCT_ACTIVE_FLG_INVALID = 456
export const CODE_EGIFT_CATALOG_EXCHANGE_PRODUCT_LIMIT_REACHED = 457

// Format Date
export const API_FORMAT_DATE = 'YYYY/MM/DD'

// Color
export const DEFAULT_LOADING_FILL = '#f08b71'
export const DEFAULT_LOADING_WIDTH = '60px'
export const DEFAULT_LOADING_HEIGHT = '60px'

// select option
export const SELECT_ALL = 0
export const DEFAULT_SORT_VALUE = 0

// FILTER SORT BY
export const PRODUCTS_ORDER_BY_ALL = 0
export const PRODUCTS_ORDER_BY_LATEST = 1
export const PRODUCTS_ORDER_BY_POPULARITY = 2
export const PRODUCTS_ORDER_BY_LOW_POINT_FIRST = 3
export const PRODUCTS_ORDER_BY_HIGHT_POINT_FIRST = 4
export const PRODUCTS_ORDER_BY_CHECKED_FLG = 5

// Language default
export const APP_LANG_DEFAULT = 1 // En
export const APP_LANG_VN = 2 // Vn
export const APP_LANG_ID = 3 // Id

// alert
export const TOASTR_SUCCESS = "alert-success"
export const TOASTR_ERROR = "alert-error"

export const PRODUCTS_PAGINATE_LIMIT = 15

// Gift card type
export const GIFT_CARD_TYPE_GIFT_BOX = 1
export const GIFT_CARD_TYPE_EGIFT = 2
export const GIFT_CARD_TYPE_IKEDAYAMA = 3
export const GIFT_CARD_TYPE_EGIFT_CATALOG = 4

// Header request
export const HEADER_REQUEST_EGIFT_CATALOG_UUID = 'egift-catalog-uuid'

// API paginate
export const API_PAGINATE_ALL = -1

// active flg
export const ACTIVE_ON_FLG = 0;
export const ACTIVE_OFF_FLG = 1;
export const ACTIVE_STOPPING_FLG = 2;

// my gifts timeout
export const MINIMUM_TIMEOUT_LOADING = 1500;
export const ALERT_TIMEOUT = 3000;

// Filter brand
export const BRANDS_ORDER_BY_NAME_ALPHABET = 1

// Store product search filter
export const SEARCH_FILTER = 'product_search_filter_';
