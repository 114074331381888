const storage = { messages: [] };

import { TOASTR_SUCCESS } from "@constants"

export default {
    name: "toastr",
    components: {},
    props: [],
    data() {
        return {
            storage: storage,
        };
    },
    methods: {
        setClass: function (classType) {
            return 'toastr alert text-center upper-first-letter ' + classType;
        },
        addToast: function (content, type = TOASTR_SUCCESS) {
            this.clear();

            this.storage.messages.push({
                'content': content,
                'type': type,
            });
        },
        clear: function () {
            this.storage.messages = [];
        },
        btnClick: function () {
           this.clear();
        }
    },
};
