import Vue from 'vue'
import Vuex from 'vuex'
import global from './modules/global'
import egiftCatalog from "./modules/egiftCatalog"
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        global,
        egiftCatalog,
    }
})
