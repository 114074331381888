<template>
  <BaseModal :modalHeader="$t('faq')" modalId="modal-faq">
    <div slot="modal-body" v-html="getContent"></div>
  </BaseModal>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script>
import BaseModal from "../BaseModal"

export default {
  name: "FaqModal",
  components: {
    BaseModal,
  },
  props: {
    faq: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getContent: function () {
      return this.faq.content || null;
    },
  },
};
</script>
