import { egiftCatalogCompositeService } from "@services";
import router from "@router";

const EGIFT_CATALOG_LANG_KEY = "egift_catalog_lang_";

export default {
    namespaced: true,
    state: {
        egiftCatalog: null,
        languages: [], // List egift catalog languages
        selectedLanguageId: null,
    },
    getters: {
        currentEgiftUrl: function (state) {
            return state.egiftCatalog?.catalog_choose_product?.egift_url ?? null;
        },
    },
    mutations: {
        setEgiftCatalog: function (state, egiftCatalog) {
            state.egiftCatalog = egiftCatalog;
        },
        setLanguages: function (state, languages) {
            state.languages = languages;
        },
        setSelectedLanguage: function (state, selectedLanguageId) {
            let uuid = state.egiftCatalog.gift_catalog_url;
            state.selectedLanguageId = parseInt(selectedLanguageId);

            // save user selected language
            sessionStorage.setItem(EGIFT_CATALOG_LANG_KEY + uuid, selectedLanguageId);
        }
    },
    actions: {
        // Fetch composite data for egift catalog
        loadInitial: async function ({ commit }, uuid) {
            const result = await egiftCatalogCompositeService.composite(uuid);

            if (result && result.data) {
                let languages = result.data.languages.data;

                // set master data
                commit('setEgiftCatalog', result.data.egift_catalog);
                commit('setLanguages', languages);

                // use to check if setting not exist in list languages
                let languageIds = languages.map((language) => language.id);

                // set language
                let selectedLanguageId = parseInt(sessionStorage.getItem(EGIFT_CATALOG_LANG_KEY + uuid));

                // setting language for this egift catalog
                if(!selectedLanguageId || !languageIds.includes(selectedLanguageId)) {
                    commit('setSelectedLanguage', result.data.egift_catalog.language);
                } else {
                    commit('setSelectedLanguage', selectedLanguageId);
                }
            } else {
                 // Redirect to page 404 error
                router.push({ path: '/error-404' }).catch(() => {});
            }
        },
    },
};
