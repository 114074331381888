import BaseService from "./BaseService";

const BASE_URI = "/faqs";

class FaqService extends BaseService {
    async findByLanguage () {
        const res = await this.get(BASE_URI + `/by-language`);
        return res.data;
    }
}

export default new FaqService();
