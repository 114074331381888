<template>
  <div v-bind:class="pointClass">
    <div class="point-icon">
      <span
        class="icon gift-point-icon img-responsive"
        :size="this.size"
      ></span>
    </div>
    <span class="point-text"> {{ this.formatPoint }} </span>
  </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script>
import { IMAGES } from "@assets";
import { formatPointNumber } from "@utils";

export default {
  name: "PointCount",
  data() {
    return {
      IMAGES,
    };
  },
  props: {
    isHeader: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 12,
    },
    point: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    pointClass: function () {
      return {
        "point-count": true,
        "point-header": this.isHeader,
      };
    },
    formatPoint: function () {
      return formatPointNumber(this.point);
    },
  },
};
</script>
