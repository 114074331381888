import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import { IMAGES } from "@assets";
import { FilterArea, BaseModal } from "../components";
import { formatPointNumber } from "@utils";
import { DEFAULT_SORT_VALUE } from "@constants";

export default {
  name: "SortModal",
  data() {
    return {
      IMAGES,
      selectedSortItemId: null,
      onlyShowCanRedeem: false,
      point: [DEFAULT_SORT_VALUE, DEFAULT_SORT_VALUE],
      options: {
        min: DEFAULT_SORT_VALUE,
        max: DEFAULT_SORT_VALUE, // max value can choose
        height: 15,
        contained: true,
      },
      formatPointNumber,
    };
  },
  props: {
    exchangeablePoint: {
      type: Number,
      required: true,
    },
    currentPoint: {
      type: Number,
      required: true,
    },
    sortItems: {
      type: Array,
      required: true,
    },
    defaultSortItemId: {
      type: Number,
      required: true,
    },
  },
  components: {
    BaseModal,
    FilterArea,
    VueSlider,
  },
  watch: {
    // whenever question changes, this function will run
    onlyShowCanRedeem: function (newValue) {
      if (newValue) {
        this.maxPoint = this.currentPoint;
      } else { // only show products can redeem
        this.maxPoint = this.exchangeablePoint;
      }

      this.point = [DEFAULT_SORT_VALUE, this.maxPoint];
    }
  },
  methods: {
    onClickItem: function (event) {
      this.selectedSortItemId = event.target.getAttribute("data-id");
    },
    submitSort: function () {
      this.$emit("submit", this.selectedSortItemId, this.point);
    },
    // Load short item data
    loadSortItemFilter: function () {
      let sortItemQuery = this.$route.query.sortItem || DEFAULT_SORT_VALUE;
      let minPriceQuery = this.$route.query.minPrice || DEFAULT_SORT_VALUE;
      let maxPriceQuery = this.$route.query.maxPrice || this.exchangeablePoint;

      this.selectedSortItemId = sortItemQuery;
      this.point = [minPriceQuery, maxPriceQuery];
    }
  },
  computed: {
    processStyle: function () {
      return {
        background:
          "linear-gradient(-45deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 43.75%), #003F65",
      };
    },
    maxPoint: {
      get: function () {
        return this.options.max;
      },
      set: function (maxPoint) {
        this.options.max = maxPoint;
      },
    },
    paddingRight: function () {
      let percent = 100 - (this.maxPoint / this.exchangeablePoint) * 100;
      return percent + "%";
    },
  },
  created() {
    this.maxPoint = this.exchangeablePoint;

    // set max point
    this.point[1] = this.maxPoint;

    this.selectedSortItemId = this.sortItems[0].id;

    // Call load sort item
    this.loadSortItemFilter();
  },
};
