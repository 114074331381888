<template>
  <div id="point-box-counter">
    <div class="point-container">
      <div class="point-body">
        <div class="line">
          <PointCount :isHeader="true" :size="32" :point="currentPoint" />
        </div>
        <div class="line">
          <p class="expired-text">
            {{
              $t('point_expiry_date', {
                  expired_date: this.getExpireDate
              })
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./styles.scss";
</style>

<script>
import PointCount from "@components/PointCount";
import { formatDate } from "@utils";

export default {
  name: "PointBoxCounter",
  components: {
    PointCount,
  },
  props: {
    currentPoint: {
      type: Number,
      required: true,
    },
    expiredDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    getExpireDate: function () {
      return formatDate(this.expiredDate);
    },
  },
};
</script>


