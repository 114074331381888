<template>
    <button class="btn btn-primary upper-first-letter" @click="$emit('click')" :disabled="disabled"> {{ this.content }} </button>
</template>

<style lang="scss" scoped>
    @import "./styles.scss";
</style>

<script>
    export default {
        name: "ButtonPrimary",
        props: {
            content: {
                type: String,
                default: 'Apply',
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
    }
</script>
