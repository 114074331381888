import { compositeApiService } from "@services";
import {
    HowToUseModal,
    FaqModal,
    TermsAndConditionModal,
}  from "./components";

export default {
    name: "FooterModal",
    data() {
        return {
            faq: {},
            howToUseFile: {},
            termsOfUse: {},
        }
    },
    components: {
        HowToUseModal,
        FaqModal,
        TermsAndConditionModal,
    },
    methods: {
        loadInitial: async function () {
            const { data } = await compositeApiService.footerModal();

            // Terms of use
            this.termsOfUse = data.terms_of_use;

            // How to use file
            this.howToUseFile = data.how_to_use_file;

            // Faq
            this.faq = data.faq;
        },
    },
    created() {
        // fetch data
        this.loadInitial();
    }
}
